#chatbox {
  width: 370px;
  height: calc(100% - 120px);
  max-height: 590px;
  position: fixed;
  right: 25px;
  bottom: 100px;
  box-sizing: border-box;
  box-shadow: 0px 7px 40px 2px rgba(148, 149, 150, 0.3);
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.3s ease-in-out;
  border-radius: 10px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  z-index: 999;
}

.show {
  display: inline !important;
}
.hide {
  display: none !important;
}

.chatOpenButton {
  width: 60px;
  z-index: 999;
  height: 60px;
  background-color: #150c68;
  background-position: center;
  background-repeat: no-repeat;
  position: fixed;
  right: 25px;
  bottom: 25px;
  border-radius: 50%;
  box-shadow: none;
  transition: box-shadow 0.2s ease-in-out;
  .chatBtn {
    color: white;
    font-size: 27px;
    padding: 16px 15px;
    -webkit-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
    -moz-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
    -ms-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
    -o-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
    transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
  }
  .closeBtn {
    color: white;
    font-size: 35px;
    padding: 13px 16px;
    -webkit-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
    -moz-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
    -ms-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
    -o-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
    transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
  }
  .newNotfi {
    background-color: red;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    position: absolute;
    top: 0;
    right: 0;
    padding: 2px;
    span {
      color: white;
      font-size: 13px;
      padding: 6px 7px;
    }
  }
}

#chat-messages {
  margin-top: 20px;
  width: 360px;
  height: calc(100% - 200px);
  max-height: 590px;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 20px;

  -webkit-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
  -moz-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
  -ms-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
  -o-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
  transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
}
#chat-messages.animate {
  opacity: 1;
  margin-top: 0;
}
#chat-messages label {
  color: #aab8c2;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  margin: 15px 0;
  width: 290px;
  display: block;
}
#chat-messages div.message {
  padding: 0 0 30px 58px;
  clear: both;
  margin-bottom: 45px;
  .name {
    float: left;
    margin-right: 0;
    margin-left: -43px;
    font-size: 9px;
    margin-top: 30px;
    color: #999999;
  }
}
#chat-messages div.message.right {
  padding: 0 58px 30px 0;
  margin-right: -19px;
  margin-left: 19px;
  .name {
    float: right;
    margin-left: 0;
    margin-right: -43px;
    font-size: 9px;
    margin-top: 30px;
    color: #999999;
  }
}
#chat-messages .message img {
  float: left;
  margin-left: -40px;
  border-radius: 50%;
  width: 30px;
}
#chat-messages div.message.right img {
  float: right;
  margin-left: 0;
  margin-right: -40px;
}

.message .bubble {
  background: #f0f4f7;
  font-size: 13px;
  font-weight: 600;
  padding: 12px 13px;
  border-radius: 5px 5px 5px 0px;
  color: #8495a3;
  position: relative;
  float: left;
}
#chat-messages div.message.right .bubble {
  float: right;
  border-radius: 5px 5px 0px 5px;
}
.bubble .corner {
  background: url("bubble-corner.png") 0 0 no-repeat;
  position: absolute;
  width: 7px;
  height: 7px;
  left: -5px;
  bottom: 0;
}
div.message.right .corner {
  background: url("bubble-cornerR.png") 0 0 no-repeat;
  left: auto;
  right: -5px;
}
.bubble span {
  color: #aab8c2;
  font-size: 11px;
  position: absolute;
  right: 0;
  bottom: -22px;
}
#sendmessage {
  height: 60px;
  border-top: 1px solid #e7ebee;
  position: absolute;
  bottom: 0;
  right: 0px;
  width: 370px;
  background: #fff;
  padding-bottom: 50px;
}

#sendmessage input {
  background: #fff;
  margin: 12px 0 0 0px;
  border: none;
  padding: 0px 20px;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400px;
  color: #aab8c2;
}
#sendmessage input:focus {
  outline: 0;
  box-shadow: none;
}
#sendmessage button {
  background: #fff url("send.png") 0 -41px no-repeat;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 15px;
  top: 23px;
  border: none;
}
#sendmessage button:hover {
  cursor: pointer;
  background-position: 0 0;
}
#sendmessage button:focus {
  outline: 0;
}

#close {
  position: absolute;
  top: 8px;
  opacity: 0.8;
  right: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
#close:hover {
  opacity: 1;
}
#chatview,
#sendmessage {
  overflow: hidden;
  border-radius: 6px;
}
