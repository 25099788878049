.LoginOrCreateBanner-container {
  .backgroundImage {
    background-repeat: no-repeat, no-repeat;
    background-position: center center, center center;
    background-size: cover, cover;
    background-image: url("./A2-Banner.jpg");
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    overflow: visible;
    z-index: 99;
    clear: both;
    background-color: rgba(0, 0, 0, 0.6);
    .footer-link {
      margin: 0;
      .left {
        text-align: left;
        width: 35%;
        float: left;
        .menui {
          display: none;
          cursor: pointer;
          font-size: 14px;
          color: #fff;
          padding-right: 10px;
          float: right;
          padding-top: 8px;
        }
      }
      .right {
        text-align: right;
        width: 65%;
        float: left;
        .body {
          display: inline;
          .item {
            display: inline;
            .dividerFooter {
              display: inline;
            }
          }
        }
      }
      a {
        color: #fff;
        font-size: 12px;
        line-height: 28px;
        white-space: nowrap;
        display: inline-block;
        margin-left: 8px;
        margin-right: 8px;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
      .nolink {
        cursor: default;
        &:hover {
          text-decoration: none;
        }
      }
      .dividerFooter {
        border-left: 1px solid white;
        height: 10px;
      }
    }
  }
}

.body {
  background: linear-gradient(-45deg, #f9dd28, #c0a80a, #5e3f99, #2f3435);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
