@import "../../../scss/variables";
.more-privgroup-container {
    .priv-container{
        margin-top: 20px;
        border-top: 1px solid #e3e9f0;
        padding-top: 20px;
        .priv{
            display: block;
            width: 100%;
            .privno{
                margin-left: 10px;
                font-weight: bold;
            }
            .privname{
                margin-left: 10px;
                font-weight: normal;
            }
        }
    }
    
}