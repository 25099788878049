@import "_variables";
@import "./icomoon/style.css";
body {
  color: #3c414e;
  font-size: 12px !important;
  user-select: none;
}
.edit-button {
  // background-color: #fff;
  padding-top: 2px;
  padding-bottom: 2px;
}
.more-button {
  // background-color: #fff;
  padding-top: 2px;
  padding-bottom: 2px;
}

.delete-button {
  // background-color: #fff;
  padding-top: 2px;
  padding-bottom: 2px;
}

.modal-section {
  margin-top: 20px;
  .label-title {
    margin-top: -29px;
  }
}

.reqSymbol {
  color: red;
}

.label-title {
  font-size: 13px;
  font-weight: bold;
  margin-top: -30px;
  padding-left: 20px;
  padding-right: 20px;
  label {
    background-color: white;
    padding: 0px 8px;
  }
  .btn-action {
    float: right;
    margin-top: -4px;
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.button {
  font-size: 11px;
}

.container-fluid {
  margin-top: 10px;
}

label {
  font-size: 11px;
  font-weight: bold;
}

body {
  font-family: Tahoma, Verdana, Helvetica, Arial;
  font-size: 11px;
  background: #f0f0f0;
  height: 100%;
  margin: 0;
  padding: 0;
}

.action-btn {
  cursor: pointer;
  color: #337ab7;
  margin-left: 5px;
  margin-right: 5px;
  &:hover {
    color: black;
  }
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #51627e;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.nopadding {
  display: contents;
  .card-body {
    padding: 0;
  }
}

.btn-primary {
  border-radius: 2px;
  color: white !important;
}
.react-date-picker {
  width: 100%;
  line {
    stroke: #888888;
  }
  rect {
    stroke: #888888;
  }
}

.react-date-picker__wrapper {
  border: 1px solid #e3e8ec !important;
  color: #3e515b !important;
  width: 100%;
  button {
    color: #e3e8ec !important;
  }
}

.saveBtn {
  float: right;
}

.btn-info {
  color: white;
  &:disabled {
    color: white;
  }
  &:hover {
    color: white;
  }
}
.Toastify {
  z-index: 99999999+2;
}

.css-15k3avv {
  z-index: 99999999+2;
}
.css-15k3avv .select__menu {
  z-index: 99999999+2;
}

.react-responsive-modal-overlay {
  z-index: 99999999+3 !important;
}

.css-vj8t7z {
  border-radius: 0px;
  border-color: hsl(207, 19%, 91%);
  min-height: 31px !important;
  height: 31px !important;
  border-radius: 0px;
}
.css-2o5izw {
  min-height: 31px !important;
  height: 31px !important;
  border-radius: 0px;
}
.css-162g8z5 {
  min-height: 31px !important;
  height: 31px !important;
  border-radius: 0px;
}
.tooltip,
.popover {
  font-size: 12px;
  z-index: 99999999+1;
}
.divider {
  border-bottom: 1px solid #dddddd;
  height: 2px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.floatLeft {
  float: left;
}

.svg-btn {
  cursor: pointer;
  font-size: 16px;
  &:hover {
    color: red;
  }
}

.modal-footer {
  margin-left: -25px;
  margin-right: -25px;
}

.invalid {
  border: 1px solid #f86c6b;
  background-color: white;
}

.suggestInvalid {
  input {
    border: 1px solid #f86c6b;
    background-color: white;
  }
  span {
    display: inline;
  }
}
.none {
  display: none;
}

.show {
  display: block;
}

.loading {
  text-align: center;
  padding: 20px;
  background-color: white;
  display: flex;
  img {
    height: 80px;
  }
  svg {
    height: 150px;
    width: 100%;
    float: left;
  }
}

.app-body {
  .main {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100vh-7vh;
    display: flex;
  }
}

.filterToggle {
  padding-top: 3px;
  float: left;
  width: 500px;
  user-select: none;
  i {
    margin-right: 5px;
    font-size: 14px;
  }
  cursor: pointer;
  &:hover {
    color: $blue;
  }
}
.btn-group {
  width: 100%;
  .dropdown-toggle {
    width: 100%;
    text-align: left;
  }
}

.thead {
  background-color: #f6f6f6;
  border-bottom: 1px solid #e3e8ec;
  border-top-left-radius: 35px;
  th {
    border: none;
    border-bottom: 1px solid #e3e7ed !important;
  }
}

.table {
  th {
    padding: 8px 20px;
  }
  td {
    padding: 5px 20px;
    border-top: 1px solid #e3e7ed !important;
  }
}

.disabledDiv {
  pointer-events: none;
  opacity: 0.6;
  user-select: none;
}

.truncate {
  width: 200px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.react-bs-container-body {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}
.react-bs-table table td {
  overflow: hidden !important;
}

.action-row {
  padding: 10px 0px;
  float: right;
  .action-btn {
    margin: 0px 5px;
  }
}

.actions-btn {
  margin-left: 2px;
  margin-right: 2px;
  padding: 2px 8px;
  color: white;
}

.ReactCrop {
  img {
    width: 350px;
    max-width: 400px;
    max-height: none;
  }
}

.react-image-process-waterMark {
  img {
    display: block;
    max-width: 150px;
    max-height: none;
    padding-bottom: 10px;
  }
}

.margin-left-00 {
  margin-right: 5px;
}

.margin-left-10 {
  margin-right: 5px;
  margin-left: 20px;
}

.margin-left-20 {
  margin-right: 5px;
  margin-left: 30px;
}

.invalid {
  border: 1px solid #f86c6b;
  background-color: white;
}
.invalid-input {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f86c6b;
}

.width {
  width: 100px;
  color: $primaryColor;
}
.card-header {
  background-color: #ffffff;
  border-bottom: 1px solid $borderColor;
}
@font-face {
  font-family: "logo";
  src: url("fonts/AveriaLibre-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "slogan";
  src: url("fonts/Ubuntu-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.logo-header-minimized {
  color: $primaryColor;
  font-size: 25px;
  padding-left: 15px;
  padding-top: 1px;
  display: none;
  &:hover {
    text-decoration: none;
  }
}
.logo-header {
  .icon {
    color: $primaryColor;
    font-size: 25px;
    padding-left: 15px;
    padding-top: 5px;
    float: left;
  }
  .text {
    float: left;
    padding-left: 5px;
    width: 110px;
    .sitename {
      display: flex;
      .tavaar {
        float: left;
        font-size: 22px;
        font-family: "logo";
        color: $sidebar-color;
      }
      .mn {
        font-size: 12px;
        font-family: "slogan";
        color: $sidebar-color;
        padding-top: 11px;
      }
    }
  }
}
.sidebar-minimized {
  .logo-header-minimized {
    display: block;
  }
  .logo-header {
    display: none;
  }
}
.logo-login {
  text-align: center;
  &:hover {
    text-decoration: none;
  }
  .icon {
    color: $primaryColor;
    font-size: 25px;
    padding-top: 5px;
    float: left;
  }
  .text {
    float: left;
    padding-left: 5px;
    width: 110px;
    .sitename {
      display: flex;
      .tavaar {
        float: left;
        font-size: 22px;
        font-family: "logo";
        color: $sidebar-color;
      }
      .mn {
        font-size: 12px;
        font-family: "slogan";
        color: $sidebar-color;
        padding-top: 11px;
      }
    }
  }
}
.btn-outline-info:hover {
  color: white;
}

.css-15k3avv,
.select__menu {
  z-index: 9999999;
}

.imgTip {
  display: block;
  font-size: 10px;
  margin-bottom: 10px;
}

.notification {
  color: black;
  text-decoration: none;
  position: relative;
  display: inline-block;
  border-radius: 2px;
}

.notification .badge {
  position: absolute;
  top: -10px;
  right: 4px;
  padding: 5px 10px;
  border-radius: 50%;
  background: red;
  color: white;
}

.DateInput_input {
  font-weight: 200;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #484848;
  background-color: #fff;
  width: 100%;
  padding: 0px 20px 2px !important;
  padding-top: 2px !important;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid transparent;
  border-left: 0;
  border-radius: 0;
}

.rowDiv {
  margin-bottom: 20px;
}

.react-bs-table-bordered {
  border: none !important;
}
.zurchil-type {
  .labelS {
    padding: 2px 8px;
    border-radius: 3px;
    font-size: 9px;
    color: white;
    padding-bottom: 4px;
  }
  .law {
    background-color: #f86c6b;
  }
  .standart {
    background-color: #337ab7;
  }
  .tushaal {
    background-color: yellow;
    color: black;
  }
  .juram {
    background-color: #3c414e;
  }
}

.status-label {
  .labelS {
    padding: 2px 8px;
    border-radius: 3px;
    color: white;
  }
  .created {
    background-color: #888888;
  }
  .pendingPay {
    background-color: #f86c6b;
  }
  .delivery {
    background-color: #337ab7;
  }
  .canceled {
    background-color: yellow;
    color: black;
  }
  .delayed {
    background-color: #3c414e;
  }
  .refunded {
    background-color: red;
  }
  .completed {
    background-color: green;
  }
}

@media screen and (max-width: 992px) {
  //  #filterTogle{
  //    display: none;
  //  }
  .filterToggle {
    width: 70%;
  }
}

.warningDiv {
  width: 100%;
  padding: 15px;
  background-color: #ffc107;
  color: white;
  font-size: 16px;
  margin-bottom: 20px;
}

.errorDiv {
  width: 100%;
  padding: 15px;
  background-color: red;
  color: white;
  font-size: 16px;
  margin-bottom: 20px;
}

.react-confirm-alert-body {
  h1 {
    font-size: 18px !important;
  }
}

.react-confirm-alert-overlay {
  z-index: 99999999 !important;
}

.DateInput_input {
  font-size: 12px !important;
}

.autocomplete-wrapper {
  width: 350px;
  position: relative;
  display: inline-block;
}

.autocomplete-wrapper > div {
  width: 100%;
}

.autocomplete-wrapper input {
  border: 1px solid #cecece;
  padding: 3px 15px;
  font-size: 14px;
  width: 100%;
}

.autocomplete-wrapper input:focus {
  border-color: #0f67ff;
  box-shadow: none;
  outline: none;
}

.autocomplete-wrapper .dropdown {
  width: 100%;
  padding: 0;
  text-align: left;
  max-height: 280px;
  overflow: hidden;
  overflow-y: auto;
  background-color: #ffffff;
  border: 1px solid #0f67ff;
  border-top: none;
}

.autocomplete-wrapper .item {
  display: block;
  cursor: pointer;
  font-size: 14px;
  padding: 15px;
}

.autocomplete-wrapper .item.selected-item {
  background-color: #0069ff;
  color: #fafbfc;
}

.autocomplete-wrapper .item:hover {
  background-color: #0069ff;
  color: #fafbfc;
}

.itemMobileName {
  display: none;
}
.react-confirm-alert-body {
  width: 360px !important;
}
.react-confirm-alert-button-group > button:first-child {
  background-color: green;
  color: white;
  font-size: 16px;
}

.react-bs-table .table-bordered > tbody > tr > td {
  user-select: all !important;
}

.gmnoprint {
  //display: none !important;
}

.gm-style-mtc {
  display: none !important;
}

.custom-confirm-ui {
  background-color: $primaryColor;
  color: white;
  text-align: center;
  padding: 40px 60px;
  border-radius: 10px;
  .btn {
    margin-left: 5px;
    margin-right: 5px;
    color: white;
    font-size: 14px;
  }
}

.searchBox {
  width: 100% !important;
}

#sc-launcher {
  z-index: 999 !important;
}

.sc-header,
.sc-launcher {
  background-color: $primaryColor !important;
}
.sc-message--content.sent .sc-message--text {
  background-color: $primaryColor !important;
}

.activeSpan {
  color: black !important;
  cursor: default !important;
}

.alertDiv {
  width: "100%";
  display: block !important;
}
