// Bootstrap overrides

//
// Color system
//
@import "_variables";
$white:                           #fff;
$gray-100:                        #dddddd;
$gray-200:                        #e3e8ec;
$gray-300:                        #bcc5d0;
$gray-400:                        #869fac;
$gray-500:                        #678898;
$gray-600:                        #94a0b2;
$gray-700:                        #3e515b;
$gray-800:                        #444d58;
$gray-900:                        #151b1e;
$black:                           #000 !default;

$blue:                            $primaryColor;
$indigo:                          #6610f2 !default;
$purple:                          #6f42c1 !default;
$pink:                            #e83e8c !default;
$red:                             #f86c6b;
$orange:                          #f8cb00;
$yellow:                          #ffc107 !default;
$green:                           #4dbd74;
$teal:                            #20c997 !default;
$cyan:                            #63c2de;

$colors: (
  blue:                           $blue,
  indigo:                         $indigo,
  purple:                         $purple,
  pink:                           $pink,
  red:                            $red,
  orange:                         $orange,
  yellow:                         $yellow,
  green:                          $green,
  teal:                           $teal,
  cyan:                           $cyan,
  white:                          $white,
  gray:                           $gray-600,
  gray-dark:                      $gray-800
);

$theme-colors: (
  primary:                        $blue,
  secondary:                      $gray-300,
  success:                        $green,
  info:                           $cyan,
  warning:                        $yellow,
  danger:                         $red,
  light:                          $gray-100,
  dark:                           $gray-800,

  blue:                           $blue,
  indigo:                         $indigo,
  purple:                         $purple,
  pink:                           $pink,
  red:                            $red,
  orange:                         $orange,
  yellow:                         $yellow,
  green:                          $green,
  teal:                           $teal,
  cyan:                           $cyan,

  gray-100:                       $gray-100,
  gray-200:                       $gray-200,
  gray-300:                       $gray-300,
  gray-400:                       $gray-400,
  gray-500:                       $gray-500,
  gray-600:                       $gray-600,
  gray-700:                       $gray-700,
  gray-800:                       $gray-800,
  gray-900:                       $gray-900
);

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-transitions:              true;
$enable-rounded:                  false;

// Body
//
// Settings for the `<body>` element.

$body-bg:                         #f0f0f0;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-size-base:                  0.6875rem;

// Breadcrumbs

$breadcrumb-bg:                   #fff;
$breadcrumb-margin-bottom:        1.5rem;
$breadcrumb-padding-y:            1rem;
$breadcrumb-padding-x:            1.25rem;

// Cards

$card-border-color:               $gray-200;
$card-cap-bg:                     $gray-100;

// Dropdowns

$dropdown-padding-y:              0;
$dropdown-border-color:           $gray-200;
$dropdown-divider-bg:             $gray-100;

// Buttons

$btn-secondary-border:            $gray-300;

// Progress bars

$progress-bg:                     $gray-100;

// Tables

$table-bg-accent:                 $gray-100;
$table-bg-hover:                  $gray-100;

// Forms

$input-group-addon-bg:            $gray-100;
$input-border-color:              $gray-200;
$input-group-addon-border-color:  $gray-200;
